footer {
  padding-top: 56px;
  background-color: $light-grey;
  position: absolute;
  width: 100%;
  bottom: 0;

  .opening-hrs {
    font-size: 21px;
    top: -62px;

    span {
      z-index: 1;
    }

    svg {
      transform: translate(-50%, -45%);
      width: 112%;
    }

  }

  .social-icons-container {
    >div{
      height: 45px;
      
      a {
        margin-right: 26px;
        
        svg {
          width: 45px;
        }
      }
    }
  }
}


@include media-breakpoint-down(xl){
  footer {
    .opening-hrs {
      top: unset;
      position: relative !important;
      margin-bottom: 34px;
      margin-top: 30px;
    }
  }
}

@include media-breakpoint-down(sm){
  footer{
    text-align: center;
    .row{
      justify-content: center;
    }
    .link{
      display: block;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
    .social-icons-container{
      &>div{
        a{
          margin-right: 13px;
          margin-left: 13px;
        }
      }
    }
  }
}