nav.navbar {
    padding-top: 64px;
    background-color: #fff;

    .navbar-brand {
        img {
            max-width: 296px;
        }
    }

    li {
        font-size: calc(18px + (22 - 18) * ((100vw - 1024px) / (1920 - 1024)));

        @media (min-width: 1920px) {
            font-size: 22px;
        }

        @media (max-width: 1024px) {
            font-size: 22px;
        }

        &.product-categories {
            @media (max-width: 1430px) and (min-width: 1200px){
                flex: 0 0 auto;
                width: 18.75% !important;
            }

            a.dropdown-toggle {
                &::after {
                    content: none;
                }

                svg.arrow {
                    margin-top: -3px;
                }

                &.show {
                    z-index: 10000;

                    svg:not(.arrow) {
                        path {
                            fill: $white;
                        }
                    }

                    svg.arrow {
                        transform: rotate(-180deg);
                    }
                }

            }

            ul {
                background-color: $pink;
                border: none;
                box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.08);
                left: 50%;
                padding: 60px 46px 20px 60px;
                transform: translateX(-50%);
                top: -10px;

                li {
                    margin-bottom: 20px;
                    position: relative;

                    &:hover {
                        &::before{
                            background-color: $white;
                        }

                        a{
                            color: $white;
                        }
                    }

                    a {
                        &:hover {
                            background-color: transparent;
                        }
                    }

                    &::before {
                        background-color: $black;
                        content: '';
                        height: 1px;
                        left: -18px;
                        position: absolute;
                        top: 13px;
                        width: 10px;
                    }

                    a {
                        font-size: 20px;
                        line-height: 24px;
                        padding: 0;
                    }
                }
            }
        }

        a:not(.dropdown-item) {
            display: inline-block;
            padding-left: 1rem !important;
            padding-right: 1rem !important;
            padding-top: 0px;
            padding-bottom: 5px;
            white-space: nowrap;

            svg:not(.arrow) {
                position: absolute;
                left: 50%;
                top: 50%;
                width: 100%;
                transform: translate(-50%, -50%);
                padding-bottom: 3px;
            }
            svg+span{
                position: relative;
            }

        }
    }
}

@include media-breakpoint-down(lg) {
    header.menu-open {
        .mobile-menu-container {
            background-color: $white;
            height: 100px;
            left: 15px;
            position: fixed;
            top: 0;
            width: calc(100vw - 30px) !important;
            z-index: 10;
        }
    }

    nav.navbar {
        padding-top: 0;

        .navbar-brand {
            padding-top: 20px;

            img {
                width: 140px;
            }
        }

        .navbar-collapse {
            padding-bottom: 140px;
            overflow: scroll;

            &.collapsing {
                height: 100vh;
                transition: none;
            }

            &.show {
                background: white;
                height: 100vh;
                left: 0;
                position: fixed;
                top: 100px;
                width: 100vw;
                z-index: 10;
            }

            .navbar-nav {
                padding-top: 40px;

                li {
                    margin-bottom: 12px;

                    &.product-categories{
                        ul{
                            transform: none;
                        }
                    }
                }
            }
        }

        .navbar-toggler {
            margin-top: 15px;

            &:not(.collapsed) {
                margin-top: 0;

                span {
                    &:first-child {
                        transform: rotate(45deg) translate(5px, 3px);
                        transition: transform .2s linear;
                    }

                    &.middle {
                        width: 0;
                        transition: width .2s linear;
                    }

                    &:last-child {
                        transform: rotate(-45deg) translate(7px, -5px);
                        transition: transform .2s linear;
                    }
                }
            }

            span {
                background-color: $black;
                display: block;
                height: 2px;
                margin-bottom: 5px;
                width: 28px;
                transform: rotate(0) translate(0, 0);
                transition: transform .2s linear, width .2s linear;
            }
        }
    }
}

@include media-breakpoint-down(sm){
    header{
        &.menu-open{
            .mobile-menu-container{
                .navbar-brand{
                    margin-top: 16px;
                }
            }
        }
        nav.navbar{
            height: 85px;
            .navbar-brand{
                position: absolute;
                left: 50%;
                // top: 0px;
                top: -1.2rem;
                transform: translate(-50%);
            }
            .navbar-toggler{
                margin-left: auto;
            }
        }
    }
}

@media screen and (max-width: 1023px) {

    body.nav-fixed{
        padding-top: 100px;
    }

    header:not(.menu-open) {
        nav.navbar {
            z-index: 4;
            position: relative;
            top: 0;
            width: 100%;
            height: 100px;
            &.fixed{
                position: fixed;
                transform: translateY(-100px);
                left: 0;
                width: 100%;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
                transition: top .2s linear;

                &.animateIn{
                    top: 100px;
                }
                &.animateOut{
                    top: 0px;
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .searchbar {
        margin-top: 3rem !important;
    }
}