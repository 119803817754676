.blog-block {
    margin-bottom: 7rem;
    .block-title {
        font-size: 35px;
        margin-bottom: 16px;
    }

    .img-container {
        height: 100%;
        width: 100%;
        border-radius: 16px;
        object-fit: cover;
    }

    .text-col {
        margin-left: -6.25% !important;
        background-color: $white;
        border: 2px solid $border-color;
        border-radius: 16px;
        padding: 50px 44px 50px;
        align-self: center;

        .text-container {
            .author-text {
                font-size: 14px;
                margin-bottom: 18px;
                font-variation-settings: "wght" 400;
            }

            .title-text {
                font-size: 50px;
                font-size: calc(50px + (30 - 50) * ((100vw - 1024px) / (1920 - 1024)));
                margin-bottom: 16px;

                @media (min-width: 1020px) {
                    font-size: 50px;
                }

                @media (max-width: 1020px) {
                    font-size: 36px;
                }
            }

            .intro-text {
                font-size: calc(16px + (20 - 16) * ((100vw - 1024px) / (1920 - 1024)));
                font-variation-settings: "wght" 400;
                margin-bottom: 16px;

                @media (min-width: 1020px) {
                    font-size: 20px;
                }

                @media (max-width: 1020px) {
                    font-size: 16px;
                }
            }
        }

        .more-button {
            z-index: 1;
        }
    }

    .more-button,
    .all-blogs {
        z-index: 1;
        margin-top: 50px;
        width: fit-content;
        transform: translateX(-50%);
        left: 50%;
    }
}

@include media-breakpoint-down(lg) {
    .blog-block {
        .text-col {
            margin-left: 0px !important;
            margin-top: -80px;

        }
    }
}

@include media-breakpoint-down(md) {

    .blog-block {
        .text-col {
            padding: 20px;
            margin-left: 0px;
            margin-top: -40px;

        }
    }

}
