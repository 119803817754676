.font-godi{
    font-family: "Godi", serif;
}

.font-abacaxi{
    font-family: "abacaxi-latin-variable", sans-serif;
}

.font-bold{
    font-weight: 600;
}

.font-var-550{
    font-variation-settings: "wght" 550;
}

@font-face {
    font-family: 'Godi';
    src: url('../fonts/AYBCEGodi-Black.woff2') format('woff2'),
        url('../fonts/AYBCEGodi-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
