.more-button {
    font-size: 1.375rem;
    position: relative;
    background-color: transparent;
    border: none;
    bottom: -12px;
    padding: 0 1rem 0 0.5rem;
    text-decoration: none;
    color: $black;
    white-space: nowrap;

    &:not(.a-tag) {
        &:hover {
            animation: more-button-wiggle .3s linear;

            @keyframes more-button-wiggle {
                0% {
                    transform: translateX(-50%) rotateZ(0);
                }

                50% {
                    transform: translateX(-50%) rotateZ(3deg);
                }

                100% {
                    transform: translateX(-50%) rotateZ(0);
                }
            }
        }
    }

    &.a-tag {
        &:hover {
            animation: a-button-wiggle .3s linear;

            @keyframes a-button-wiggle {
                0% {
                    transform: rotateZ(0);
                }

                50% {
                    transform: rotateZ(3deg);
                }

                100% {
                    transform: rotateZ(0);
                }
            }
        }
    }

    svg {
        height: 40px;
        width: 100%;
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
    }
}

input {

    &:active,
    &:focus {
        box-shadow: none !important;
        outline: none !important;
    }
}

.form-group {
    label {
        color: $label-color;
        font-size: 16px;
        font-variation-settings: "wght" 400;
        margin-bottom: 2px;
    }

    input, textarea, select {
        border: 1px solid $input-border-color;
        border-radius: 4px;
        font-size: 16px;
        font-variation-settings: "wght" 500;
        min-height: 40px;
    }

    .error {
        color: $error;
        font-family: "abacaxi-latin-variable", sans-serif;
        font-size: 14px;
        font-variation-settings: "wght", 500;
    }
}

button {
    background-color: transparent;
    border: 0;
    color: $black;
    font-size: 26px;
    font-variation-settings: "wght" 900;
    width: fit-content;

    &:active,
    &:focus {
        box-shadow: none !important;
        border: 0;
        outline: none !important;
    }

    &:hover {
        background-color: transparent;
        color: $black;
    }

    .svg-container {
        left: -7%;
        height: 104%;
        top: 0;
        width: 114%;
        z-index: -1;

        svg {
            height: 100%;
            width: 100%;
        }
    }
}

.buy-button {
    background-color: transparent;
    border: 0;
    color: $black;
    font-size: calc(25px + (30 - 25) * ((100vw - 1024px) / (1920 - 1024)));
    font-variation-settings: "wght" 900;
    padding: 5px 10px;
    width: fit-content;
    z-index: 1;

    @media (min-width: 1920px) {
        font-size: 30px;
    }

    @media (max-width: 1020px) {
        font-size: 25px;
    }

    &:hover {
        background-color: transparent;
        color: $black;
    }
    &:disabled {
        opacity: 0.5;
    }
}

.react-tabs {
    margin-top: 70px;

    ul {
        li {
            cursor: pointer;
            margin-right: 50px;
            padding-bottom: 16px;
            position: relative;

            &.react-tabs__tab--selected {
                &::before {
                    background-color: $green;
                    bottom: 0;
                    content: '';
                    height: 6px;
                    position: absolute;
                    width: 100%;
                }
            }

            span {
                font-size: 22px;
                padding-top: 28px;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        ul {
            margin-top: 30px;
            padding-left: 0;

            li {
                span {
                    font-size: 18px;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        ul {
            li {
                span {
                    font-size: 15px;
                    padding-top: 0;
                }
            }
        }
    }
}

.blog-card-container,
.event-card-container {
    @include media-breakpoint-up(xl) {
        flex: 0 0 auto;
        width: 33.3%;
    }

    &:nth-child(2) {
        margin-top: 100px;
    }

    &:nth-child(3) {
        margin-top: 50px;
    }

    .blog-card,
    .event-card {
        flex: 1 1 auto;
        margin-bottom: 56px;

        img {
            border-radius: 16px 16px 0 0;
            max-height: 250px;
            object-fit: cover;
            width: 100%;
        }

        .placeholder-box {
            border-radius: 16px 16px 0 0;
            max-height: 250px;
            height: 250px;
            object-fit: cover;
            width: 100%;
        }

        .text-container {
            border-radius: 0 0 16px 16px;
            border-bottom: 2px solid $border-color;
            border-left: 2px solid $border-color;
            border-right: 2px solid $border-color;
            padding: 20px;

            .date-outer-container {
                z-index: 1;
            }

            .date-container {
                background-color: $white;
                border-radius: 5px;
                margin-right: 80px;
                margin-top: -92px;
                overflow: hidden;
                width: 113px;

                .date {
                    font-size: 50px;
                }

                .month {
                    background-color: $blue-light;
                    font-size: 22px;
                    padding: 10px 0 8px 0;
                }

                &.smaller {
                    width: 90px;

                    .date {
                        font-size: 40px;
                    }

                    .month {
                        background-color: $blue-light;
                        font-size: 18px;
                        padding: 8px 0 6px 0;
                    }
                }
            }

            .date-time,
            .address {
                svg {
                    margin-right: 20px;
                }

                span {
                    margin-bottom: 8px;
                }
            }

            h2 {
                font-size: calc(24px + (30 - 24) * ((100vw - 1020px) / (1920 - 1020)));

                @media (min-width: 1920px) {
                    font-size: 30px;
                }

                @media (max-width: 1020px) {
                    font-size: 24px;
                }
            }

            p {
                font-size: 1.125rem;
                line-height: 1.75rem;
            }

            .more-button {
                bottom: -12px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 2;

                &.plus {
                    bottom: 18px;
                }

                svg.plus-icon {
                    height: unset;
                    left: 50%;
                    transform: translateX(-53%);
                    top: unset;
                    width: 70px;
                }
            }
        }
    }

    .blog-card {
        .placeholder-box {
            background-color: $green;
        }
    }

    .event-card {
        .placeholder-box {
            background-color: #f16549;
        }
    }

    @include media-breakpoint-down(lg) {
        &:nth-child(2) {
            margin-top: 0;
        }

        &:nth-child(3) {
            margin-top: 0;
        }
    }

    @include media-breakpoint-down(md) {

        .blog-card,
        .event-card {
            .text-container {
                .date-container {
                    margin-right: 0;
                    margin-top: -70px;
                    width: 70px;

                    .date {
                        font-size: 26px;
                    }

                    .month {
                        font-size: 16px;
                        padding: 8px 0 6px 0;
                    }
                }
            }
        }
    }
}

form {
    svg.creditcard {
        max-width: 50px;
    }
}