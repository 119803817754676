.login-component, .signup-component{
    button{
        background-color: transparent;
        border: 0;
        color: $black;
        font-size: 26px;
        font-variation-settings: "wght" 900;
        margin-top: 60px;
        width: fit-content;

        &:hover{
            background-color: transparent;
            color: $black;
        }

        
        .svg-container{
            left: 0;
            top: 4px;
            height: 100%;
            width: 100%;
            z-index: -1;

            svg{
                height: 100%;
                width: 100%;
            }
        }
    }
     .forgotten-password {
            font-size: 16px;
            text-decoration: none;
            font-variation-settings: "wght" 500;
            color: $label-color;
            margin-top: -7px;
            padding-left: 1px;
        }
}

.signup-component{
    h2{
        font-size: 20px;
    }

    .already-user{
        font-size: 18px;
    }
}


.forgotten-password-component {
    .password-reset-msg {
        .password-reset-text {
            margin-bottom: 30px;
        }
        .back-to-login {
            left: 50%;
        }
    }
    button{
        background-color: transparent;
        border: 0;
        color: $black;
        font-size: 26px;
        font-variation-settings: "wght" 900;
        margin-top: 10px;
        width: fit-content;

        &:hover{
            background-color: transparent;
            color: $black;
        }
    }
        .back-to-login {
            font-size: 16px;
            text-decoration: none;
            font-variation-settings: "wght" 500;
            color: $label-color;
            margin-top: -7px;
            padding-left: 1px;
        }
}

@include media-breakpoint-down(md){
    .signup-component{
        max-height: 80vh;
        overflow: scroll;
    }
}

