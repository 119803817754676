.product-detail {
  width: 100vw;
  height: 100vh;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: #ffffffa6;

  .product-detail-modal {
    position: absolute;
    left: 50%;
    width: 100%;
  }

  .previous-product,
  .next-product {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    width: 45px;
    margin: 10px;
  }

  .next-product {
    right: -75px;
  }

  .previous-product {
    left: -75px;
    transform: translateY(-50%) rotate(180deg);
  }

  .wgt-500 {
    font-variation-settings: "wght" 500;
  }

  .bg-card {
    background: $white;
    border-radius: 8px;
    box-shadow: 0 2px 60px 0 rgba(0, 0, 0, 0.12);
    padding: 28px 30px;
    max-height: 100vh;

    .close {
      cursor: pointer;
      right: 18px;
      top: 18px;

      svg {
        height: 42px;
        width: fit-content;
      }

      .back {
        svg {
          height: 22px;
          width: 22px;
        }
      }
    }

    .product-type {
      position: absolute;
      top: 60px;
      right: 100px;

      svg {
        height: 40px;
        width: 40px;
      }
    }

    .price-container {
      width: fit-content;
      height: 49px;
      margin-top: 16px;
      display: flex;

      .item-purchased {
        font-size: 33.6px;
        margin-left: 16px;
      }

      .price {
        .product-price {
          font-size: calc(26px + (33.6 - 26) * ((100vw - 1024px) / (1920 - 1024)));

          @media (min-width: 1920px) {
            font-size: 33.6px;
          }

          @media (max-width: 1020px) {
            font-size: 26px;
          }

        }

        span {
          white-space: nowrap;
          position: relative;

          &.strikethrough {
            position: absolute;
            top: 0px;
            font-size: 12px;
            text-decoration: line-through;
            text-decoration-thickness: 1.5px;
            text-decoration-color: black;
            opacity: 60%;
            left: 50%;
            transform: translateX(-50%);
            padding-left: 5px;
            padding-right: 5px;

            +span {
              display: inline-block;
              transform: translateY(8px)
            }
          }

          &.font-small {
            font-size: 12px;
          }
        }


        svg {
          width: 119%;
          min-height: 49px;
          height: 109%;
          top: 0px;
          position: absolute;
          left: -10%;
        }

        .product-price {
          z-index: 1;
        }
      }
    }

    .product-detail-extra-images {
      margin-right: 50px;
      margin-top: 20px;

      .img-container {
        border-radius: 8px;
        cursor: pointer;
        height: 90px;
        margin-right: 30px;
        width: 90px;

        &.active {
          border: 1px solid $green;
          padding: 8px;

          img {
            max-height: 72px;
          }
        }

        img {
          max-height: 90px;
          aspect-ratio: 1/1;
          border-radius: 8px;
        }
      }
    }

    .product-detail-image {
      @media (min-width: 1920px) {
        margin-right: 50px;
      }

      .product-img-container {
        cursor: zoom-in;
        width: 100%;
        position: relative;
        overflow: hidden;
        aspect-ratio: 1/1;
        border-radius: 16px;

        &.zoomed {
          cursor: zoom-out;
        }

        .product-img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-size: 100%;
          background-repeat: no-repeat;
          background-position: top center;
          object-fit: cover;
        }
      }

      .banner{
        position: absolute;
        top: 10px;
        right: 10px;
        width: 25%;
        z-index: 2;
        svg{
          width: 100%;
        }
      }
    }

    .product-detail-text {
      margin-top: calc(40px + (116 - 40) * ((100vw - 1200px) / (1920 - 1200)));

      @media (min-width: 1920px) {
        margin-top: 116px;
      }

      @media (max-width: 1020px) {
        margin-top: 40px;
      }

      .product-title {
        font-size: calc(40px + (50 - 40) * ((100vw - 1024px) / (1920 - 1024)));
        line-height: 1;

        @media (min-width: 1920px) {
          font-size: 50px;
        }

        @media (max-width: 1020px) {
          font-size: 40px;
        }
      }

      .product-category {
        font-size: calc(12px + (14 - 12) * ((100vw - 1024px) / (1920 - 1024)));
        text-transform: uppercase;
        letter-spacing: 3px;
        font-variation-settings: "wght" 500;

        @media (min-width: 1920px) {
          font-size: 14px;
        }

        @media (max-width: 1020px) {
          font-size: 12px;
        }
      }

      .product-description {
        margin-top: 30px;
        font-variation-settings: "wght" 500;
      }

      .tag {
        letter-spacing: 0.5px;
        font-size: 14px;
        height: 20px;
        min-width: 44px;
        width: fit-content;
        border-radius: 14px;
        background-color: #cfbf8d;
        text-align: center;
        margin-bottom: 40px;
        font-variation-settings: "wght" 600;
        margin-right: 8px;
        padding-left: 8px;
        padding-right: 8px;
        vertical-align: middle;
      }

      .button-container {
        // bottom: 40px;
        // left: 50%;
        // transform: translateX(-50%);
        position: absolute;
        bottom: 0;
        margin-bottom: 40px;
        margin-top: auto;
        background-color: white;

        .purchase-done,
        .purchase-failed {
          button {
            .text {
              position: relative;
              z-index: 1;
            }

            .svg-container {
              left: -5%;
              width: 110%;
              z-index: 0;
            }
          }
        }

        .purchase-failed {
          button {
            span {
              font-size: 24px;
            }
          }
        }

        button {
          .order {
            font-size: 22px;
            border-bottom: 2px solid $blue-light;
          }

          .svg-container {
            left: 0;
            top: 0;
            right: 50%;
            height: 100%;
            width: 100%;
            z-index: -1;

            svg {
              height: 100%;
              width: 100%;
            }
          }
        }

        .product-cart-adder-container {
          border: 1px solid $border-color;
          border-radius: 4px;
          padding: 22px 20px;

          button.card-link {
            margin-top: 10px;

            .spinner-border {
              color: $white !important;
            }
          }
        }
      }
    }
  }

  .zoom-container {
    position: relative;
  }

  .zoom-result {
    background-color: $white;
    position: absolute;
    width: 150px;
    height: 150px;
    border: 1px solid #000;
    display: none;
    background-size: 400%;
    /* Adjusted to 200% */
    background-repeat: no-repeat;
    pointer-events: none;
  }
}

.product-creditcard-modal {
  .bg-card {
    .inner-container {
      .product-container {
        .title {
          font-size: calc(18px + (30 - 18) * ((100vw - 1024px) / (1920 - 1024)));
          line-height: 1;

          @media (min-width: 1920px) {
            font-size: 30px;
          }

          @media (max-width: 1020px) {
            font-size: 18px;
          }

          margin-bottom: 20px;
        }

        .save-card-btn {
          margin-top: 20px;
          z-index: 0;
        }
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .product-detail {
    .previous-product {
      left: -60px;
    }

    .next-product {
      right: -60px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .product-detail {

    .previous-product,
    .next-product {
      width: 30px;
      margin: 10px;
    }

    .bg-card {
      height: 100vh;
      border-radius: 0px;
      box-shadow: none;
      overflow-x: hidden;

      .product-container {
        padding-top: 85px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .product-detail {
    .product-detail-modal {
      background-color: $white;
      top: 0 !important;
      transform: translate(-50%, 0%) !important;
    }

    .bg-card {
      padding: 0 0 200px 0;

      .inner-container {
        height: fit-content;
      }

      .close {
        z-index: 1;
        cursor: pointer;
      }

      .product-container {
        padding-top: 80px;
      }
    }

    .next-product,
    .previous-product {
      display: none;
    }

    .button-container {
      position: relative !important;
    }
  }
}