.search-container{
    margin-bottom: 4rem;
    input{
        background: transparent;
        font-size: 20px;
        height: 44px;
        position: relative;
        padding-left: 23px;
        padding-right: 34px;
        z-index: 1;
        -webkit-appearance: none;
        
        &::-webkit-input-placeholder{
            color: $black !important;
        }
    }

    .search-bg{
        top: -5px;

        svg.bg{
            width: 105%;
        }

        .search-icon{
            // right: 2%;
            right: 16px;
            top: 12px;
            overflow: hidden;
            &.close{
                cursor: pointer;
                z-index: 2;
            }
        }
    }

    .search-input{
        .svg-container{
            height: 120%;
            left: -3%;
            width: 115%;
            z-index: -1;

            svg{
                height: 100%;
                width: 100%;
            }
        }
    }
}

@include media-breakpoint-down(lg){
    .search-container{
        .search-bg{
            width: 100%;

            svg.bg{
                width: 100%;
            }
        }
    }
}