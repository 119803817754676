.quote-block {
    margin-bottom: 40px;
    margin-top: 25px;

    .outer-container{
        padding-right: 45px;
        padding-top: 4px;
    }
    
    .text{
        span, p{
            font-size: 20px;
            line-height: 32px;
        }
        
        span{
            font-size: 18px;
            font-variation-settings: "wght" 500;
            margin-bottom: 16px;
        }
    }

    svg.quote-mark {
        margin-top: 16px;
        max-width: 48px;
        height: 55px;
        min-width: 48px;
    }

    svg.bg {
        height: 100%;
        left: -20px;
        top: 0;
        width: 100%;
        z-index: -1;
    }
}