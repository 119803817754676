.image-list-block {
    margin-bottom: 100px;

    .outer-row {
        scroll-snap-type: x mandatory;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        overflow: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        .img-container {
            flex: 0 0 auto;
            width: 33.33%;
            scroll-snap-align: start;

            img {
                border-radius: 16px;
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
        }
    }

    .next-img-btn, .prev-img-btn {
        cursor: pointer;
        height: 100%;
        position: absolute;
        right: -50px;
        top: 50%;
        transform: translateY(-50%);
        width: 60px;

        svg {
            height: 66px;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .prev-img-btn {
        left: -50px;
    }
}