.godi-modal {
    z-index: 100;
    background: #fff;
    position: relative;
    margin: auto;
    border-radius: 32px;
    max-width: 1400px;
    width: 80%;
    padding: 185px 70px;
    pointer-events: all;


    &--overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 80;
        background-color: rgba(42,181,115,0.9);
    }

    &--wrapper {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 90;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        outline: 0;
        display: flex;
        align-items: center;
        pointer-events: none;
    }


    &--header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &--close-button {
        cursor: pointer;
        border: none;
        background: transparent;
        position: absolute;
        right: 13px;
        top: 13px;

        svg{
            height: 42px;
            width: 42px;
        }
    }

    ul{
        li{
            cursor: pointer;
            opacity: .5;

            &:first-child{
                margin-right: 40px;
            }

            &.react-tabs__tab--selected{
                opacity: 1;

                .svg-container.signup{
                    
                    svg{
                        path{
                            fill: #FFDE4F;
                        }
                    }
                }
                
                .svg-container.login{
                    svg{
                        path{
                            fill: #8781bd;
                        }
                    }
                }
            }

            span{
                font-size: 26px;
                font-variation-settings: "wght" 900;
                z-index: 1;
            }

            .svg-container{
                left: -5%;
                
                svg{
                    height: 110%;
                    width: 110%;

                    path{
                        fill: #E3E3E3;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(md){
    .godi-modal{
        border-radius: 0;
        height: 100vh;
        margin: 0;
        padding: 80px 20px;
        width: 100vw;
        position: fixed;
        top: 0;

        ul{
            padding-left: 0;
            
            li{
                span{
                    font-size: 20px;
                }
            }
        }
        .signup-component{
            button[type="submit"]{
                margin-top: 20px;
                margin-bottom: 100px;
            }
        }
    }
}