.userbar {
    position: absolute;
    top: -40px;
    right: 30px;
    z-index: 10;

    .dropdown-open{
        .dropdown{
            .nav-link.login{
                width: 270px;

                svg{
                    margin-left: auto;
                }
            }
        }
    }

    .login-menu{
        font-family: "Godi", serif;
        
        div{
            color: $black;
        }
        
        .dropdown{
            cursor: pointer;

            .nav-link.login{
                font-size: 20px;
                padding-left: 21px;
                padding-right: 21px;
                position: relative;
                z-index: 70;
            }
            
            .dropdown-menu {
                box-shadow: 0 10px 30px 0 rgba(0,0,0,0.08);
                margin-top: -50px;
                padding: 70px 21px 21px 21px;
                right: 0;
                width: 270px;
                z-index: 60;

                li:not(.log-out){
                    position: relative;

                    &::before{
                        content: '';
                        background-color: $black;
                        height: 1px;
                        left: 0;
                        position: absolute;
                        top: 20px;
                        width: 10px;
                    }

                    a{
                        font-size: 20px;

                        &:active{
                            background: transparent;
                            color: $black;
                        }
                    }

                }

                li{
                    button{
                        font-size: 22px;
                        border: none;
                        
                        span{
                            z-index: 1;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(lg){
    .userbar{
        position: relative;
        right: unset;
        text-align: center;
        top: unset;
        
        ul{
            justify-content: center;
        }
    }
}
