html {}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "abacaxi-latin-variable", sans-serif;
    min-height: 100vh;
    padding-bottom: 440px;
    position: relative;
    overflow-x: hidden;

    @include media-breakpoint-down(md) {
        padding-bottom: 740px;
    }
}

a {
    color: $black;
    display: inline-block;
    text-decoration: none;
    width: fit-content;

    &.link, .paragraph-block & {
        border-bottom: 1px solid $blue-light;
        line-height: 23px;
    }
}

p,
span {
    font-variation-settings: "wght" 400;
}

p {
    font-size: 20px;
    font-size: calc(17px + (20 - 17) * ((100vw - 768px) / (1920 - 768)));
    line-height: calc(28px + (38 - 28) * ((100vw - 768px) / (1920 - 768)));

    @media (min-width: 1920px) {
        font-size: 20px;
        line-height: 32px;
    }

    @media (max-width: 768px) {
        font-size: 17px;
        line-height: 28px;
    }
}

*:focus {
    box-shadow: none !important;
}


h1,
h2,
h3 {
    font-family: "Godi", serif;
}

h1 {}

h2 {
    font-size: 1.875rem;
    line-height: 2.0625rem;
}

h3 {}

h4 {}

h5 {}

h6 {}

.overflow-scroll {
    overflow: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
}

.cursor-pointer{
    cursor: pointer;
}