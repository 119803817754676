.order-list{
    .table{
        tr{
            border-radius: 4px;

            th{
                color: $table-head-color;
                font-variation-settings: "wght" 500;
                font-size: 15px;
            }
            
            h3{
                font-size: 24px;
            }
            
            td{
                font-variation-settings: "wght" 400;
                padding: 10px 12px;
                vertical-align: middle;

                &:first-child{
                    width: 150px;
                }
            }
        }
        
        img{
            border-radius: 4px;
            width: 150px;
        }
    }
}

@include media-breakpoint-down(md){
    .order-list{
        .table{
            table{
                min-width: 400px;

                h3{
                    font-size: 18px;
                }

                td{
                    font-size: 15px;
                }

                img{
                    width: 60px;
                }
            }
        }
    }
}