.more-events {
    margin-bottom: 7rem;
    .event-card-container {
        .event-card {
            .text-container {
                .date-outer-container {
                    z-index: 1;
                }

                .date-time,
                .address {
                    svg {
                        margin-right: 20px;
                    }

                    span {
                        margin-bottom: 8px;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .more-events {
        .event-card-container {
            .event-card {
                .text-container {
                    .date-container {
                        margin-right: 0;
                        margin-top: -70px;
                        width: 70px;

                        .date {
                            font-size: 26px;
                        }

                        .month {
                            font-size: 16px;
                            padding: 8px 0 6px 0;
                        }
                    }
                }
            }
        }
    }
}