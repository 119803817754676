.my-pages {
    h1 {
        font-size: calc(35px + (50 - 35) * ((100vw - 1024px) / (1920 - 1024)));
        margin-right: calc(50px + (100 - 50) * ((100vw - 1024px) / (1920 - 1024)));
        line-height: 18px;
        white-space: nowrap;

        @media (min-width: 1920px) {
            font-size: 50px;
        }

        @media (max-width: 1024px) {
            font-size: 35px;
            line-height: 40px;
        }
    }


}

iframe#three-dee-secure {
    width: 500px;
    height: 600px;
}

