$white: #FFFFFF;
$black: #000000;

$light-grey: #FAFAFA;

$border-color: #EFEFEF;
$input-border-color: #E3E3E3;

$label-color: #7A7A7A;
$table-head-color: #4a4a4a;
$table-row-bg-color: rgba(239, 239, 239, .5);

$blue-light: #53B7E7;
$orange: #F16549;
$pink: #F17DB1;

$error: #ce1616;