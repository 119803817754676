.form-page {
    margin-bottom: 5rem;
    margin-top: 4rem;
    scroll-behavior: auto!important;
    .form-group {

        input,
        textarea,
        select {
            padding: 0.375rem 0.75rem;

            &:active,
            &:focus {
                outline: none;
            }
        }

        input[type='checkbox'],
        input[type='radio'] {
            min-height: unset !important;
            width: fit-content;
            margin-right: 3px;
        }
        input[type='file']{
            color: $label-color;
            font-size: 16px;
            font-variation-settings: "wght" 400;
            margin-bottom: 2px;
            border: none;
            padding-left: 0;
        }
    }

    .button {
        input {
            &:active,
            &:focus,
            &:focus-visible {
                box-shadow: none !important;
                border: none;
                outline: none !important;
            }
        }

        .svg-container {
            z-index: -1;
        }
    }
}