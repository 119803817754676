.product-card-container {
    .card {
        cursor: pointer;
        border: none;

        &:hover {
            .card-body {
                .card-image {
                    img {
                        transform: scale(1.1);
                        transition: transform .2s linear;
                    }
                }

                .price {
                    animation: wiggle .3s linear;
                }

                @keyframes wiggle {
                    0% {
                        transform: translate(-50%, 50%) rotateZ(0);
                    }

                    50% {
                        transform: translate(-50%, 50%) rotateZ(3deg);
                    }

                    100% {
                        transform: translate(-50%, 50%) rotateZ(0);
                    }
                }
            }
        }
    }

    .card-body {
        border-radius: 16px;
        overflow: hidden;
        
        .card-image {
            img {
                transform: scale(1);
                transition: transform .2s linear;
            }
            svg{
                position: absolute;
                z-index: 2;
                width: 35%;
                right: 10px;
                top: 10px;
            }
        }
    }

    .price {
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translate(-50%, 50%);
        padding: 5px 10px 10px 10px;
        font-size: 22px;

        svg {
            width: 119%;
            min-height: 49px;
            top: 0px;
            position: absolute;
            left: -10%;
            z-index: -1;
        }

        span {
            white-space: nowrap;

            &.strikethrough{
                position: absolute;
                top: 0px;
                font-size: 12px;
                text-decoration: line-through;
                text-decoration-thickness: 1.5px;
                text-decoration-color: black;
                opacity: 60%;
                left: 50%;
                transform: translateX(-50%);
                padding-left: 5px;
                padding-right: 5px;

                + span {
                    display: inline-block;
                    transform: translateY(4px)
                }
            }

            &.font-small{
                font-size: 12px;
            }
        }
    }
}

@include media-breakpoint-down(xl) {
    .product-card-container {
        .price {
            span {
                font-size: 18px;
            }
        }
    }
}