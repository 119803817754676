.page-eventpage{
    .date-outer-container{
        z-index: 1;
    }

    .date-container{
        background-color: $white;
        border-radius: 5px;
        margin-right: 80px;
        margin-top: -92px;
        overflow: hidden;
        width: 113px;

        .date{
            font-size: 50px;
        }
        
        .month{
            background-color: $blue-light;
            font-size: 22px;
            padding: 10px 0 8px 0;
        }
    }

    .date-time, .address{
        svg{
            margin-right: 20px;
        }

        span{
            margin-bottom: 8px;
        }
    }

    .signup-col{
        margin-bottom: 80px;

        &::before{
            background-color: $border-color;
            content: '';
            height: 104%;
            left: 15px;
            position: absolute;
            top: -2%;
            width: 1px;
        }

        .inner-padding{
            padding-left: 39px;

            h3{
                font-size: 24px;
            }
        }
    }
}

@include media-breakpoint-down(lg){
    .page-eventpage{
        .date-container{
            width: 100px;
            
            .date{
                font-size: 38px;
                padding: 10px 0;
            }

            .month{
                font-size: 18px;
                padding: 6px 0 4px 0;
            }
        }

        .signup-col{
            &::before{
                content: none;
            }

            .inner-padding{
                padding-left: 0;
            }
        }
    }
}