.paragraph-block{
    h2, h3, h4{
        font-family: "abacaxi-latin-variable", sans-serif;
        font-variation-settings: "wght" 600;
        margin-bottom: 12px;
    }

    p{
        font-variation-settings: "wght" 300 !important;
    }

    img{
        border-radius: 16px;
        height: auto;
        width: 100%;
        
        &.full-width{
            margin: 40px 0;
            max-height: 495px;
            object-fit: cover;
            width: 100%;
        }

        &.left{
            float: left;
            margin: 25px 30px 50px 0;
            max-height: 300px;
            width: auto;
        }

        &.right{
            float: right;
            margin: 25px 0 50px 30px;
            max-height: 300px;
            width: auto;
        }
    }
}

@include media-breakpoint-down(md){
    .paragraph-block{
        img{
            &.left, &.right{
                max-height: 180px;
            }
        }
    }
}

@include media-breakpoint-down(sm){
    .paragraph-block{
        img{
            &.left, &.right{
                float: unset;
                margin: 20px 0;
                max-height: unset;
                max-width: 100%;
            }
        }
    }
}