.page-blogindexpage,
.page-blogpage,
.page-eventindexpage,
.page-eventpage {
    h1 {
        font-family: "Godi";
        margin-bottom: 37px;
        margin-top: 90px;
    }


}



@include media-breakpoint-down(lg) {

    .page-blogindexpage,
    .page-eventindexpage {

        .blog-card-container,
        .event-card-container {
            &:nth-child(2) {
                margin-top: 0;
            }

            &:nth-child(3) {
                margin-top: 0;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .page-blogindexpage .page-eventindexpage {

        .blog-card-container,
        .event-card-container {
            &:nth-child(2) {
                margin-top: 0;
            }
        }
    }
}