$grid-columns: 16;
$grid-gutter-width: 30px;

@media (max-width: 768px){
  $grid-gutter-width: 15px;
}

$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  md: 768px,
  lg: 1024px,
  xl: 1200px,
  xxl: 1400px
);

.clamped{
    max-width: 1920px;
}