.page-blogpage, .page-eventpage, .page-standardpage{
    .blog-page-header, .event-page-header, .standard-page-header{
        margin-bottom: 150px;

        h1{
            margin-bottom: 40px;
            margin-top: 40px;
        }

        .title-container{
            margin-top: -75px;
            max-width: 730px;
            padding: 30px;
            z-index: 1;

            h2{
                font-size: calc(28px + (50 - 28) * ((100vw - 1020px) / (1920 - 1020)));
                line-height: calc(38px + (58 - 38) * ((100vw - 1020px) / (1920 - 1020)));

                @media (min-width: 1920px){
                    font-size: 50px;
                    line-height: 58px;
                }

                @media (max-width: 1020px){
                    font-size: 28px;
                    line-height: 38px;
                }
            }

            svg{
                left: -27px;
                height: 100%;
                top: 0;
                width: 104%;
                z-index: -1;
            }
        }

        img{
            border-radius: 16px;
            object-fit: cover;
            width: 100%;
            max-height: 585px;
            object-position: center;
        }

        .caption-container{
            font-size: 16px;
            font-variation-settings: "wght" 400;
            margin-top: 10px;

            svg{
                left: -10%;
                height: 200%;
                min-height: 138%;
                width: 120%;
                z-index: -1;
            }
        }

        .placeholder-box{
            border-radius: 16px;
            width: 100%;
            max-height: 585px;
            height: 700px;
        }

        .author{
            margin-top: 17px;

            span{
                font-size: 16px;
                line-height: 28px;
            }
        }
    }

    .event-page-header {
        .placeholder-box {
            background-color: #f16549;
        }
    }

    .blog-page-header {
        .placeholder-box {
            background-color: $green;
        }
    }

    .intro{
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
    }

    .more-blogs, .more-events{
        margin-top: 100px;

        h3{
            font-size: 30px;
            margin-bottom: 30px;
        }

        .blog-card-container, .event-card-container{
            &:nth-child(2){
                margin-top: 0 !important;
            }
    
            &:nth-child(3){
                margin-top: 0 !important;
            }
        }

        a.see-all-blogs, a.see-all-events {
            display: inline-block;
            font-size: 22px;
            margin-top: 90px;
            padding-left: 1rem !important;
            padding-right: 1rem !important;
            padding-top: 0px;
            padding-bottom: 5px;

            svg {
                position: absolute;
                left: 50%;
                height: 43px;
                top: 50%;
                z-index: -1;
                width: 100%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

@include media-breakpoint-down(lg){
    .page-blogpage, .page-eventpage, .page-standardpage{
        .blog-page-header, .event-page-header, .standard-page-header{
            margin-bottom: 40px;

            img{
                min-height: 300px;
            }

            .blog-title, .event-title{
                flex-direction: column;
                position: relative !important;

                .title-container{
                    margin-top: -40px;
                    padding: 20px 10px 10px 10px;
                }
            }
        }
    }
}

@include media-breakpoint-down(md){
    .page-blogpage, .page-eventpage, .page-standardpage{
        .blog-page-header, .event-page-header, .standard-page-header{
            .title-container{
                padding: 14px 0;

                svg{
                    left: -11px;
                }
            }

            .caption-container{
                margin-top: 3px;
            }

            .date-container {
                margin-right: 20px;
                margin-top: -140px;
                width: 70px;

                .date {
                    font-size: 26px;
                }

                .month {
                    font-size: 16px;
                    padding: 8px 0 6px 0;
                }
            }

            .author{
                margin-left: unset;
                margin-right: 40px;
                margin-top: 0;
                text-align: right;

                span{
                    font-size: 13px;
                    line-height: 15px;
                }
            }
        }

        .more-blogs, .more-events{
            margin-top: 60px;
        }
    }
}